import React from 'react'
import { FooterWrapper, FooterCell, Img, Heading4, Text } from './FooterElements'
import { Wrapper } from './HeaderElements'
import { Link } from 'react-router-dom'
import archiveImg from '../../images/archive.png'
import contactImg from '../../images/contact.png'

const Footer = () => {
    return (
        <>
            <FooterWrapper>
                <Wrapper>
                    <FooterCell>
                        <Link to="/arhiva-dokumenata">
                            <Img src={archiveImg} alt="archive" />
                            <Heading4>Архива докуменaтa</Heading4>
                        </Link>
                    </FooterCell>
                    <FooterCell>
                        <Link to="/kontakt">
                            <Img src={contactImg} alt="contact" />
                            <Heading4>Контактирајте нас</Heading4>
                        </Link>
                    </FooterCell>
                </Wrapper>
            </FooterWrapper>
            <FooterWrapper copyright>
                <Wrapper>
                    <Text>
                        Власник ауторских права:  ЈП ,,Аеродром Росуље" Крушевац, Србија
                </Text>
                </Wrapper>
            </FooterWrapper>
        </>
    )

}

export default Footer