import React, { useState } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { Section, Wrapper, Heading1, Heading3, Span, Text, Links, BackImg, Left, Right, List, Item } from '../Pages/PocetnaElements'
import { Img } from '../Layout/HeaderElements'
import Pocetna from '../Pages/Pocetna'
import Opreduzecu from '../Pages/Opreduzecu'
import AnalizaPoslovanja from '../Pages/AnalizaPoslovanja'
import CiljeviPlanovi from '../Pages/CiljeviPlanovi'
import Kontakt from '../Pages/Kontakt'
import Organizacija from '../Pages/Organizacija'
import VizijaIMisija from '../Pages/VizijaIMisija'
import KljucneAktivnosti from '../Pages/KljucneAktivnosti'
import AnalizaTrzista from '../Pages/AnalizaTrzista'
import RiziciPoslovanja from '../Pages/RiziciPoslovanja'
import PlaniraneAktivnosti from '../Pages/PlaniraneAktivnosti'
import menuImg from '../../images/menu.png'

import ugovorPripajanje2 from '../../images/ugovor-o-pripajanju2.pdf'
import izvestajOPromeni from '../../images/izvestaj-o-statusnoj-promeni.pdf'
import program2020pdf from '../../images/predlog2020.pdf'
import program2019pdf from '../../images/program2019.pdf'
import program2018pdf from '../../images/program2018.pdf'
import fi2017pdf from '../../images/fi2017.pdf'
import fi2016pdf from '../../images/fi2016.pdf'
import fi2015pdf from '../../images/fi2015.pdf'
import Arhiva from '../Pages/Arhiva'


const Main = () => {

    const [open, setOpen] = useState(false);

    const openMenu = () => {
        setOpen(!open);
    }

    return (
        <>
            <Img menuToggle onClick={openMenu} src={menuImg} alt="menu" />
            <main>
                <Section welcomeBanner></Section>
                <Wrapper toUpper>
                    <Left className={open ? "open" : "closed"}>
                        <List>
                            <Heading3>Општи подаци</Heading3>
                            <Item>
                                <Link onClick={openMenu} to="/o-preduzecu">Подаци о предузећу</Link>
                                <Link onClick={openMenu} to="/o-preduzecu/misija-i-vizija">Визија, мисија и стратегија</Link>
                                <Link onClick={openMenu} to="/o-preduzecu/organizaciona-struktura">Структура управљања</Link>
                            </Item>
                            <Heading3>Анализа пословања</Heading3>
                            <Item>
                                <Link onClick={openMenu} to="/analiza-poslovanja">Процењени обим активности</Link>
                            </Item>
                            <Heading3>Циљеви и планови</Heading3>
                            <Item>
                                <Link onClick={openMenu} to="/ciljevi-i-planovi">Циљеви и разлози</Link>
                                <Link onClick={openMenu} to="/ciljevi-i-planovi/kljucne-aktivnosti">Кључне активности</Link>
                                <Link onClick={openMenu} to="/ciljevi-i-planovi/analiza-trzista">Анализа тржишта</Link>
                                <Link onClick={openMenu} to="/ciljevi-i-planovi/rizici-poslovanja">Ризици пословања</Link>
                                <Link onClick={openMenu} to="/ciljevi-i-planovi/planirane-aktivnosti">Планиране активности</Link>
                            </Item>
                            <Heading3>Програми и извештаји</Heading3>
                            <Item>
                                <Link onClick={openMenu} to={ugovorPripajanje2} target="_blank">Уговор о припајању <span>Документ</span></Link>
                                <Link onClick={openMenu} to={izvestajOPromeni} target="_blank">Извештај о промени статуса <span>Документ</span></Link>
                                <Link onClick={openMenu} to={program2020pdf} target="_blank">Програм пословања 2020 <span>Документ</span></Link>
                                <Link onClick={openMenu} to={program2019pdf} target="_blank">Програм пословања 2019 <span>Документ</span></Link>
                                <Link onClick={openMenu} to={program2018pdf} target="_blank">Програм пословања 2018 <span>Документ</span></Link>
                                <Link onClick={openMenu} to={fi2017pdf} target="_blank">Финансијски извештај 2017 <span>Документ</span></Link>
                            </Item>
                        </List>
                    </Left>
                    <Right>
                        <Switch>
                            <Route exact path="/" component={Pocetna} />
                            <Route exact path="/o-preduzecu" component={Opreduzecu} />
                            <Route path="/o-preduzecu/misija-i-vizija" component={VizijaIMisija} />
                            <Route path="/o-preduzecu/organizaciona-struktura" component={Organizacija} />
                            <Route exact path="/analiza-poslovanja" component={AnalizaPoslovanja} />
                            <Route exact path="/ciljevi-i-planovi" component={CiljeviPlanovi} />
                            <Route path="/ciljevi-i-planovi/kljucne-aktivnosti" component={KljucneAktivnosti} />
                            <Route path="/ciljevi-i-planovi/analiza-trzista" component={AnalizaTrzista} />
                            <Route path="/ciljevi-i-planovi/rizici-poslovanja" component={RiziciPoslovanja} />
                            <Route path="/ciljevi-i-planovi/planirane-aktivnosti" component={PlaniraneAktivnosti} />
                            <Route exact path="/kontakt" component={Kontakt} />
                            <Route exact path="/arhiva-dokumenata" component={Arhiva} />
                        </Switch>
                    </Right>
                </Wrapper>

            </main>
        </>
    )
}

export default Main
