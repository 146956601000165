import React from 'react'
import { HeaderWrapper, Wrapper, Logo, Img, TopNav, NavItem, Text, TopWrapper } from './HeaderElements'
import { Link } from 'react-router-dom'
import logoImg from '../../images/logo.png'



const Header = () => {
    return (
        <>
            <TopWrapper>
                <Wrapper justified>
                    <Text>Пословно име:  ЈП „Аеродром Росуље“ Крушевац</Text>
                    <Text>Седиште:  Трг Косовских Јунака бр. 6, 37000 Крушевац</Text>
                </Wrapper>
            </TopWrapper>
            <HeaderWrapper>
                <Wrapper justified>
                    <Logo>
                        <Link to="/">
                            <span>ЈП</span> Аеродром Росуље
                        </Link>
                    </Logo>
                    <TopNav>
                        <NavItem>
                            <Link to="/">ПОЧЕТНА</Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/o-preduzecu">О ПРЕДУЗЕЋУ</Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/analiza-poslovanja">АНАЛИЗА ПОСЛОВАЊА</Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/ciljevi-i-planovi">Циљеви и разлози</Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/kontakt">КОНТАКТ</Link>
                        </NavItem>
                    </TopNav>
                </Wrapper>
            </HeaderWrapper>
        </>
    )

}

export default Header