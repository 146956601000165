import React from 'react'
import { Content, Heading1, Heading2, ContentText, Text, List, Item, Pdf } from './PagesContent'
import { Link } from 'react-router-dom'
import pdfDoc from '../../images/predlog2020.pdf';

const PlaniraneAktivnosti = () => {
    return (
        <Content>
            <Heading1>Планиране активности</Heading1>
            <ContentText>
                <Heading2>Активности које јавно преџеће планира да спроведе у циљу унапређења корпоративног управљања</Heading2>
                <Text>
                    У наредном периоду радиће се и на унапређењу транспарентности пословања Јавног
                    предузећа и отклаљању евентуалних неусаглашености са законским и подзаконским актима
                    који се односе на пословање Јавног предузећа. Такође, биће интензивиране и окончане
                    активности на мапирању пословних процеса и управљања ризиком.
                </Text>
                <Pdf>
                    <Text>
                        За детаљније информације, преузмите PDF документ.
                    </Text>
                    <Link to={pdfDoc} target="_blank">Преузмите документ</Link>
                </Pdf>
            </ContentText>
        </Content>

    )
}

export default PlaniraneAktivnosti
