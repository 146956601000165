import React from 'react'
import { Content, Heading1, Heading2, ContentText, Text, List, Item, Pdf } from './PagesContent'
import { Link } from 'react-router-dom'
import pdfDoc from '../../images/predlog2020.pdf';

const KljucneAktivnosti = () => {
    return (
        <Content>
            <Heading1>Кључне активности</Heading1>
            <ContentText>
                <Heading2>Кључне активности потребне за достизање циљева</Heading2>
                <Text>
                    Оспособљавање аеродрома Росуље у Паруновцу постићи ће се завршетком радова на
                    изградњи хангара и радова на издрадњи рулнице и платформе, ради повезивања са
                    травнатом пистом.
                </Text>
                <Text>
                    У предстојећем периоду биће предузете све прописане активности неопходне за
                    спровођење статусне промене припајања Јавног предузећа привредном друштву
                    „Аеродроми Србије“ д.о.о.
                </Text>
                <Pdf>
                    <Text>
                        За детаљније информације, преузмите PDF документ.
                    </Text>
                    <Link to={pdfDoc} target="_blank">Преузмите документ</Link>
                </Pdf>
            </ContentText>
        </Content>

    )
}

export default KljucneAktivnosti
