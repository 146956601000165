import React from 'react'
import { Content, Heading1, Heading2, ContentText, Text, List, Item, Pdf } from './PagesContent'
import { Link } from 'react-router-dom'
import pdfDoc from '../../images/predlog2020.pdf';

const VizijaIMisija = () => {
    return (
        <Content>
            <Heading1>Визија, мисија и стратегија</Heading1>
            <ContentText>
                <Heading2>Визија</Heading2>
                <Text>
                    Визија предузећа је повезивање централног дела Србије ваздушним путем са
                    европским дестинацијама. Привлачењем страних и домаћих инвеститора допринеће
                    унапређењу развоја региона.

                </Text>
                <Heading2>Мисија</Heading2>
                <Text>
                    Сопственим развојем и усавршавањем Јавно предузеће жели бити генератор развоја
                    туризма Расинског округа и локалне привреде.
                </Text>
                <Heading2>Дугорочни и средњорочни план пословне стратегије и развоја друштва</Heading2>
                <Text>
                    Након преноса удела и оснивачка права у Јавном предузећу, са Града Крушевца на
                    Републику Србију, извршена је регистрација промене код Агенције за привредне регистре.
                    У предстојећем периоду, имајући у виду наведену измену, извршиће се потребне
                    активности у вези са плановима пословних стратегија и развоја.
                </Text>
                <Pdf>
                    <Text>
                        За детаљније информације, преузмите PDF документ.
                    </Text>
                    <Link to={pdfDoc} target="_blank">Преузмите документ</Link>
                </Pdf>
            </ContentText>
        </Content>

    )
}

export default VizijaIMisija
