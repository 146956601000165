import styled from 'styled-components'
import airplaneImg from '../../images/airplane.png'



export const Section = styled.section`
    width: 100%;
    display: block;

    ${props => {
        if(props.welcomeBanner){
            return `
                background: url(${airplaneImg}) center center no-repeat;
                background-size: cover;
                background-attachment: fixed;
                padding: 0 20px;
                height: 515px;
                position: relative;

                ::before{
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    background: #19324dcc;
                }

                @media (max-width: 1310px) {
                    height: 475px;
                }

                @media (max-width: 960px) {
                    height: 500px;
                    background-attachment: unset;
                }

                @media (max-width: 690px) {
                    height: 350px;
                }
            `
        } else {
            return `
                background: initial;
            `
        }
    }}

`
export const Wrapper = styled.div`
    width: 1270px;
    display: flex;
    min-height: 800px;
    margin: 0 auto;
    position: relative;
    padding: 50px 0 20px 0;
    justify-content: ${props => props.justified ? "space-between" : "start"};

    ${props => {
        if(props.toUpper){
            return `
                margin-top: -515px;

                @media (max-width: 960px) {
                    margin-top: -475px;
                    min-height: unset;
                    padding-top: 0;
                }
            `
        }
    }}
    
    @media (max-width: 1310px) {
        width: 100%;
    }
`
export const Home = styled.div`
    padding: 10px 0 0 30px;

    @media (max-width: 960px) {
        padding: 10px 0 0 0;
    }
`
export const Left = styled.div`
    width: 310px;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 1;
    box-shadow: 0 20px 20px rgba(0,0,0,0.03);

    @media (max-width: 1310px) {
        padding-top: 10px;
    }

    @media (max-width: 960px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 111;
        padding-top: 0;
        height: 100%;
        background: #fff;
        display: none;
        box-shadow: 0 0 150px rgba(0,0,0,0.75);
        width: 400px;

        &.open {
            display: block;
        }

        @media (max-width: 690px){
            width: 280px;
            overflow: auto;
        }
        @media (max-width: 320px){
            width: 250px;  
        }
    }
`

export const Right = styled.div`
    width: 100%;
    padding-left: 330px;

    @media (max-width: 1310px) {
        padding-top: 10px;
    }

    @media (max-width: 960px) {
        padding: 10px 20px 0 20px;
    }
`
export const Heading3 = styled.h3`
    padding: 10px 15px;
    background: linear-gradient(90deg, rgba(36,65,97,1) 0%, rgba(65,106,152,1) 100%);
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1em;
    border-left: 5px solid #c7363c;
    position: relative;

    @media (max-width: 960px) {
        font-size: 1.2em;
    }

    @media (max-width: 690px){
        font-size: 1em;
    }
`
export const List = styled.ul`
    width: 100%;
    display: block;
    list-style-type: none;
`
export const Item = styled.li`
    display: block;

    a{
        display: block;
        padding: 8px 20px;
        color: #19324d;
        text-decoration: none;
        border-bottom: 1px solid rgba(0,0,0,0.075);
        font-weight: 500;
        font-size: .92em;
        background: #ffffff;
        position: relative;

        @media (max-width: 960px) {
            padding: 10px 20px;
            font-size: 1em;
            font-weight: 400;
        }

        @media (max-width: 690px){
            font-size: .8em;
            padding: 8px 15px;
        }
        @media (max-width: 320px){
            font-size: .7em;
            font-weight: 500;
        }

        :hover{
            text-decoration: underline;
            color: #c7363c;
        }

        span{
            position: absolute;
            right: 10px;
            top: 7px;
            background: #c7363c;
            color: #fff;
            padding: 3px 7px;
            border-radius: 2px;
            font-size: .75em;
            text-transform: uppercase;
        }
    }
`
export const Heading1 = styled.h1`
    font-weight: 700;
    font-size: 5em;
    color: #fff;
    display: block;
    position: relative;
    z-index: 1;

    @media (max-width: 1310px) {
        font-size: 3.5em;
    }

    @media (max-width: 960px) {
        text-align: center;
        padding-top: 50px;
    }
    @media (max-width: 690px) {
        padding-top: 150px;
        font-size: 2em;

        span{
            margin-top: 10px;
        }
    }
    @media (max-width: 320px){
        font-size: 1.75em;
    }
`
export const Heading2 = styled.h2`
    font-weight: 600;
    font-size: 2.5em;
    color: #fff;
    display: block;
`
export const Span = styled.span`
    display: block;
    font-size: .55em;
    font-weight: 600;
    position: relative;
    z-index: 1;

    ${props => {
        if(props.beforeLine){
            return `
                position: relative;
                z-index: 1;
                padding-left: 70px;
                margin-top: 20px;

                @media (max-width: 960px) {
                    padding-left: 0;
                }

                ::before{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 60px;
                    height: 3px;
                    background: #fff;
                    content: '';
                    display: block;

                    @media (max-width: 960px) {
                        display: none;
                    }
                }
            ` 
        }
    }}
`
export const Text = styled.p`
    display: block;
    font-style: italic;
    color: #fff;
    margin: 30px 0;
    font-size: 1.75em;
    position: relative;
    z-index: 1;

    @media (max-width: 1310px) {
        font-size: 1.5em;
    }
    @media (max-width: 960px) {
        text-align: center;
    }

    @media (max-width: 690px) {
        font-size: 1.2em;
    }

    ${props => {
        if(props.description){
            return `
                color: #19324d;
                margin-top: 120px;
                background:#fff;
                padding: 30px 40px;
                border-left: 15px solid #c7363c;
                font-size: 1.45em;
                box-shadow: 0 20px 20px rgba(0,0,0,0.03);
                font-style: normal;
                margin-bottom: 0;

                @media (max-width: 1310px) {
                    margin-top: 100px;
                }
                @media (max-width: 960px) {
                    text-align: left;
                    font-size: 1.35em;
                    line-height: 1.5em;
                    margin-top: 160px;
                }

                @media (max-width: 690px) {
                    margin-top: 80px;
                    padding: 20px 30px;
                }
                @media (max-width: 320px){
                    font-size: 1.2em;
                }

                span{
                    font-weight: 700;
                }
                
                a{
                    display: block;
                    margin-top: 5px;
                    font-size: .75em;
                    color: #c7363c;
                    font-weight: 600;
                    padding: 10px 0;

                    :hover{
                        text-decoration: none;
                    }
                }
            `
        }
    }}
`
export const Links = styled.div`
    display: flex;
    gap: 20px;

    @media (max-width: 960px) {
        justify-content: center;
        gap: 15px;
    }

    a{
        color: #fff;
        background: #c7363c;
        padding: 10px 20px 13px 20px;
        border-radius: 3px;
        text-decoration: none;
        font-weight: 500;
        position: relative;
        z-index: 1;

        :last-child{
            background: #fff;
            color: #19324d;
        }

        @media (max-width: 690px) {
            font-size: .9em;
        }
    }
`