import React from 'react'
import { Content, Heading1, Heading2, ContentText, Text, List, Item, Pdf } from './PagesContent'
import { Link } from 'react-router-dom'
import pdfDoc from '../../images/predlog2020.pdf';

const AnalizaPoslovanja = () => {
    return (
        <Content>
            <Heading1>Анализа пословања</Heading1>
            <ContentText>
                <Heading2>Процењени физички обим активности у 2019. години</Heading2>
                <Text>
                    Влада Републике Србије је на седници, одржаној 20.09.2018. године, донела Закључак
                    о прихватању Извештаја о стању на Аеродрому Росуље (закључак 05 број: 343-5879/2019.
                    год.), као одговор на иницијативу Града Крушевца ресорном министарству - Министарству
                    грађевинарства, саобраћаја и инфраструктуре РС, за обезбеђење додатне финансијске и
                    техничке помоћи која је неопходна за развој Аеродрома Росуље. Поменути Закључак Владе
                    је значајно утицао на ниво активности ЈП „Аеродром Росуље“ током 2019. године.

                </Text>
                <Text>
                    Скупштина Града Крушевца је на седници одржаној 24.04.2019. год. донела Одлуку
                    о приступању реализацији преноса ЈП „Аеродрома Росуље“ на Републику Србију (I број
                    023-68/19), а у вези закључка Владе Републике Србије (05 број 343-5879/2019 од 13.06.2019.
                    год.). Одлуком се налаже Граду Крушевцу и ЈП „Аеродром Росуље“ да спроведу активности
                    и предузму мере у циљу спровођења препорука, ради реализације преноса имовине и права
                    и обавеза ЈП „Аеродром Росуље“ са града Крушевца на Републику Србију.
                </Text>
                <Text>
                    На седници одржаној 20.09.2019. године Скупштина града Крушевца донела је следеће
                    акте: Одлуку о преносу удела и оснивачких права над Јавним предузећем „Аеродром
                    Росуље“ на Републику Србију (I број 023-68/19) и Одлуку о преносу права јавне својине
                    Града Крушевца у јавну својину Републике Србије непосредном погодбом и без накнаде на
                    непокретности на грађевинском земљишту које чини комплекс аеродрома „Росуље“ (I број:
                    464-3/19).
                </Text>
                <Text>
                    У 2019. години Јавно предузеће добило је донацију од Контроле летења Србије и Црне
                    Горе SMATSА-е у износу од 500.000,00 динара.
                </Text>
                <Pdf>
                    <Text>
                        За детаљније информације, преузмите PDF документ.
                    </Text>
                    <Link to={pdfDoc} target="_blank">Преузмите документ</Link>
                </Pdf>
            </ContentText>
        </Content>

    )
}

export default AnalizaPoslovanja
