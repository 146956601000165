import React from 'react'
import { Section, Wrapper, Home, Heading1, Heading3, Span, Text, Links, BackImg, Left, Right, List, Item } from './PocetnaElements'
import { Link } from 'react-router-dom'
import grbImg from '../../images/symbol.png'

const Pocetna = () => {
    return (
        <>
            <Home>
                <Heading1>
                    <Span>Јавно предузеће</Span>
                „Аеродром Росуље“
                <Span beforeLine>Крушевац</Span>
                </Heading1>
                <Text>Добродошли на званичну презентацију предузећа...</Text>
                <Links>
                    <Link to="/o-preduzecu">О предузећу</Link>
                    <Link to="/o-preduzecu/organizaciona-struktura">Организација</Link>
                </Links>
                <Text description>
                    <span>Јавно предузеће „Аеродром Росуље“ Крушевац</span>,
                    основано је oдлуком СО Крушевац о оснивању
                    јавног предузећа „Аеродром Росуље“ 28.02.1995.
                    
                    Седиште Јавног предузећа је у Крушевцу,
                    Трг Косовских јунака број 6.
                    <Link to="/o-preduzecu">Више информација</Link>
                </Text>
            </Home>
        </>
    )
}

export default Pocetna
