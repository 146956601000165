import React from 'react'
import { Content, Heading1, Heading2, ContentText, Text, List, Item } from './PagesContent'
import { Link } from 'react-router-dom'
import program2020pdf from '../../images/predlog2020.pdf'
import program2019pdf from '../../images/program2019.pdf'
import program2018pdf from '../../images/program2018.pdf'
import fi2017pdf from '../../images/fi2017.pdf'
import fi2016pdf from '../../images/fi2016.pdf'
import fi2015pdf from '../../images/fi2015.pdf'
import izvestajOPromeni from '../../images/izvestaj-o-statusnoj-promeni.pdf'
import ugovorPripajanje2 from '../../images/ugovor-o-pripajanju2.pdf'

const Arhiva = () => {
    return (
        <Content>
            <Heading1>Архива докумената</Heading1>
            <ContentText>
                <Heading2>Кликните на документ за приказ</Heading2>
                <Link className="archive" to={ugovorPripajanje2} target="_blank">Уговор о припајању</Link>
                <Link className="archive" to={izvestajOPromeni} target="_blank">Извештај о промени статуса</Link>
                <Link className="archive" to={program2020pdf} target="_blank">Програм пословања 2020</Link>
                <Link className="archive" to={program2019pdf} target="_blank">Програм пословања 2019</Link>
                <Link className="archive" to={program2018pdf} target="_blank">Програм пословања 2018</Link>
                <Link className="archive" to={fi2017pdf} target="_blank">Финансијски извештај 2017</Link>
                <Link className="archive" to={fi2016pdf} target="_blank">Финансијски извештај 2016</Link>
                <Link className="archive" to={fi2015pdf} target="_blank">Финансијски извештај 2015</Link>
            </ContentText>
        </Content>

    )
}

export default Arhiva
