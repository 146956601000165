import React, { useEffect } from 'react'
import Footer from './components/Layout/Footer'
import Header from './components/Layout/Header'
import Main from './components/Layout/Main'
import ScrollToTop from './components/ScrollToTop'
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom'

const App = () => {

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Main />
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App
