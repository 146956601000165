import styled from 'styled-components'

export const HeaderWrapper = styled.header`
    background: #ffffff;
    min-height: 70px;
    display: flex;
    width: 100%;
    padding: 15px 20px;
    justify-content: center;
    position: relative;
    z-index: 111;
`
export const TopWrapper = styled.div`
    background: #19324d;
    padding: 5px 20px 8px 20px;
    border-top: 5px solid #c7363c;
`
export const Wrapper = styled.div`
    width: 1270px;
    display: flex;
    margin: 0 auto;
    
    @media (max-width: 1310px) {
        width: 100%;
    }

    @media (max-width: 690px){
        flex-direction: column;
    }
    
    ${props => {
        if (props.justified) {
            return `
            justify-content: space-between;

                @media (max-width: 1000px){
                    p {
                        font-size: .8em;
                    }
                }
                @media (max-width: 690px){
                    p {
                        font-size: .65em;
                        line-height: 1.5em;
                    }
                }
            `
        }
    }}

`
export const Text = styled.p`
    font-weight: 400;
    font-size: .9em;
    color: #fff;
`
export const Logo = styled.div`
    width: 300px;
    height: 100%;
    display: flex;
    font-weight: 700;
    font-size: 1.5em;
    align-items: center;
    font-style: italic;
    
    a{
        text-decoration: none;
        color: #19324d;
    }

    span{
        color: #c7363c;
    }

    @media (max-width: 690px){
        width: 250px;
        font-size: 1.2em;
    }
`
export const Img = styled.img`
    max-width: 100%;
    display: block;

    ${props =>{
        if(props.menuToggle){
            return `
                display: none;
                width: 47px;
                height: 47px;
                position: absolute;
                top: 47px;
                right: 20px;
                z-index: 111;

                @media (max-width: 1000px) {
                    display: block;
                }

                @media (max-width: 690px){
                    top: 63px;
                    width: 40px;
                    height: 40px;
                }
            `
        }
    }}
`
export const TopNav = styled.ul`
    display: flex;

    @media (max-width: 1000px) {
        display: none;
    }
`
export const NavItem = styled.li`
    display: flex;

    a {
        color: #19324d;
        text-decoration: none;
        line-height: 45px;
        text-transform: uppercase;
        padding: 0 15px;
        font-weight: 500;

        @media (max-width: 1310px) {
            font-size: .9em;
            padding: 0 10px;
        }
    }
    
`