import React from 'react'
import { Content, Heading1, Heading2, ContentText, Text, List, Item, Pdf } from './PagesContent'
import { Link } from 'react-router-dom'
import pdfDoc from '../../images/predlog2020.pdf';

const RiziciPoslovanja = () => {
    return (
        <Content>
            <Heading1>Ризици пословања</Heading1>
            <ContentText>
                <Heading2>Ризици пословања</Heading2>
                <Text>
                    Управљање ризицима обухвата идентификовање, мерење и процену ризика са циљем
                    минимизирања њихових негативних ефеката на финансијски резултат и капитал.
                    Управљање ризиком обухвата скуп управљачких метода и техника које се користе да би се
                    смањила могућност остварења нежељених и штетних догађаја и последица и тиме повећала
                    могућност остварења планираних резултата.
                </Text>
                <Text>
                    Вероватноћа да се планиране активности неће доследно остварити предузеће наводи
                    да препознаје, процењује и управља потенцијалним ризицима који угрожавају пословање.
                </Text>
                <Text>
                    Предузеће препознаје ризике, који су бројни због фазе развоја у којој се налази, али на
                    најзначајније не може много да утиче.
                </Text>
                <Text>
                    У циљу свођења ризика ликвидности на минималан ниво у делу о планираним
                    расходима истакнуто је да ће се планиране позиције реализовати до нивоа расположивих
                    прихода како од оснивача тако и од донатора. На тај начин од ризика ликвидности биће
                    заштићен и буџет оснивача.
                </Text>
                <Pdf>
                    <Text>
                        За детаљније информације, преузмите PDF документ.
                    </Text>
                    <Link to={pdfDoc} target="_blank">Преузмите документ</Link>
                </Pdf>
            </ContentText>
        </Content>

    )
}

export default RiziciPoslovanja
