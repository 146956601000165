import styled from 'styled-components'

export const Content = styled.div`
    width: 100%;
    min-height: 440px;
    background: #fff;
    padding-bottom: 20px;
    box-shadow: 0 20px 20px rgba(0,0,0,0.03);

    @media (max-width: 690px) {
        margin-top: 140px;
    }
`
export const ContentText = styled.div`
    padding: 0 50px 30px 50px;

    @media (max-width: 1310px) {
        padding: 0 30px 30px 30px;
    }

    @media (max-width: 690px) {
        padding: 0 20px 20px 20px;
    }

    a.archive {
        display: block;
        background: #f8f8f8;
        color: #19324d;
        text-decoration: none;
        padding: 15px;
        margin-bottom: 15px;
        border-left: 5px solid #c7363c;
        font-weight: 500;
        box-shadow: 0 3px 3px rgba(0,0,0,0.075);
        transition: all 0.2s;

        :hover{
            background: #c7363c;
            color: #fff;
        }
        @media (max-width: 320px) {
            padding: 10px;
            font-size: .9em;
        }
    }
    
`
export const Heading1 = styled.h1`
    font-size: 2.5em;
    color: #19324d;
    padding: 0 30px;
    background: linear-gradient(90deg, rgba(239,239,239,1) 0%, rgba(255,255,255,1) 100%);
    height: 120px;
    display: flex;
    align-items: center;
    border-left: 20px solid #c7363c;
    font-weight: 800;
    text-transform: uppercase;

    @media (max-width: 1310px) {
        font-size: 2em;
        height: auto;
        display: block;
        padding: 20px 30px;
    }

    @media (max-width: 690px) {
        font-size: 1.5em;
        padding: 20px;
        border-left: 15px solid #c7363c;
    }
`
export const Heading2 = styled.h2`
    color:#19324d;
    font-weight: 400;
    font-size: 2em;
    margin: 30px 0;

    @media (max-width: 690px) {
        font-size: 1.5em;
    }
`
export const Text = styled.p`
    margin-bottom: 15px;
    color:#19324d;
    line-height: 1.5em;
    font-size: 1.1em;

    @media (max-width: 690px) {
        font-size: 1em;
    }

    span{
        font-weight: 700;
        margin: 0 0 5px 0;
        display: block;
    }
    
    .grayed{
        background: #efefef;
        padding: 10px 15px;
        border-left: 10px solid #dcdcdc;
    }

    :last-child{
        margin-bottom: 0;
    }
`
export const List = styled.ul`
    list-style-type: circle;
    margin: 10px 0 20px 25px;

    @media (max-width: 690px) {
        margin: 10px 0 20px 10px;
    }
`
export const Item = styled.li`
    display: block;
    margin-bottom: 15px;

    span{
        font-size: 1em;
        margin: 0;
    }
`
export const Pdf = styled.div`
    display: block;
    background: linear-gradient(90deg,rgba(36,65,97,1) 0%,rgba(65,106,152,1) 100%);
    padding: 20px 30px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 690px) {
        margin-top: 30px;
        padding: 15px 20px;
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
    }

    p{
        color: #fff;
        margin: 0;

        @media (max-width: 960px) {
            font-size: .9em;
        }
    }

    a{
        background: #fff;
        color: #000;
        text-decoration: none;
        padding: 10px 20px;
        border-radius: 3px;
        font-weight: 500;

        @media (max-width: 960px) {
            text-align: center;
        }
        @media (max-width: 690px) {
            text-align: center;
            margin-top: 10px;
            font-size: .9em;
        }
    }
`
