import React from 'react'
import { Content, Heading1, Heading2, ContentText, Text, List, Item, Pdf } from './PagesContent'
import { Link } from 'react-router-dom'
import pdfDoc from '../../images/predlog2020.pdf';

const CiljeviPlanovi = () => {
    return (
        <Content>
            <Heading1>Циљеви и разлози</Heading1>
            <ContentText>
                <Heading2>Циљеви јавног предузећа у 2020. години</Heading2>
                <Text>Оспособљавање аеродрома Росуље у Паруновцу и</Text>
                <Text>Спровођење поступка статусне промене припајања привредном друштву
                            „Аеродроми Србије“ д.о.о. Ниш</Text>
                <Heading2>Разлози за реализацију пројекта:</Heading2>
                <Text>
                    - Своју пословну активност у граду Крушевцу имају фирме „Kromberg&Schubert“,
                    LIDL, NEPI, док фирме попут DS Smith-a, Cooper Tires-а, Henkela, планирају проширење
                    пословне делатности на територији нашег града. Наведене компаније могле би да користе
                    аеродром за превоз како путника тако и робе. У сарадњи са ПУ и царином, и њиховим
                    изласком на лицу места знатно би се убрзао овакав транспорт.
                </Text>
                <Text>
                    - Град Крушевац има бројну дијаспору (велики број родбине наших суграђана је на
                    привременом раду у иностранству) који би лакше могли да путују до родног краја, што за
                    резултат има пораст локалних прихода услед веће потрошње.
                </Text>
                <Text>
                    - Крушевац са околином поседује значајне потенцијале и предуслове за развој
                    пољопривредне производње, а то значи и потребу за коришћењем привредне авијације и
                    аеродрома као одговарајуће инфраструктуре. Третирање зелених површина ради заштите од
                    губара, уништавање комараца и сл. услуге из ваздуха. Поред наведеног, засејавање
                    градоносних облака сребројодидом као једниман од савремених начина противградне
                    одбране и остали еколошки процеси су, такође, изводљиви са локације аеродрома Росуље.
                </Text>
                <Text>
                    - Аеродром Росуље имао би изузетно велики значај у случају ванредних околности,
                    попут елементарних непогода и помоћи у акцијама ватрогасне службе у смислу гашења
                    пожара и његовог лоцирања, код неприступачних терена.
                </Text>
                <Text>
                    - Хитан транспорт оболелих или повређених лица, хитна достава донираних органа као
                    и у случају хитних медицинских интервенција, такође би се могли извршити са будућег
                    аеродрома.
                </Text>
                <Text>
                    - Град Крушевац у перспективи планира да аеродром Росуље користи и у сврхе превоза
                    робе (терета). Тачније, исти би могао служити за превоз, пре свега, пољопривредних
                    производа (воћа, поврћа, шумских плодова, свежег меса) као и воћно-садног материјала и
                    калемова винове лозе.
                </Text>
                <Text>
                    - Аеродром би могао да се користи и као школски центар за обуку професионалних
                    пилота, пилота једрилица, обуку пилота ултра лаких авиона и обуку падобранаца, како
                    локалних, тако и иностраних, имајући у виду да би услуге биле далеко повољније у односу
                    на друге земље.
                </Text>
                <Text>
                    - Авио -извиђање и осматрање и снимање терена, туристичко панорамско надлетање
                    града, туристички падобрански тандем скокови, као и надлетање изнад града са
                    транспаретним рекламама у циљу промоције и пружања обавештења грађанима.
                </Text>
                <Text>
                    - Највише бенефита изградњом аеродрома имаће локална економија. Све напред наведене
                    повољности аеродрома односе се, не само на град Крушевац и Расински округ, већ и шире.
                    Садашњи трендови говоре да су будућност авио-саобраћаја управо мали аеродроми.
                </Text>
                <Text>
                    - Свака инвестиција у инфраструктуру ваздушног саобраћаја је исплатива, јер сама
                    инфраструктура са собом повлачи низ других развојних потенцијала, пре свега односи се на
                    локални економски развој, затим на привлачењу страних инвеститора, развој туризма и
                    пружање могућности грађанима да упознају и користе све могућности које авијација пружа.
                </Text>
                <Pdf>
                    <Text>
                        За детаљније информације, преузмите PDF документ.
                    </Text>
                    <Link to={pdfDoc} target="_blank">Преузмите документ</Link>
                </Pdf>
            </ContentText>
        </Content>

    )
}

export default CiljeviPlanovi
