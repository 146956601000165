import styled from 'styled-components'

export const FooterWrapper = styled.footer`
    display: block;

    div{
        gap: 30px;
        
        @media (max-width: 960px) {
            padding: 0 20px;
            gap: 20px;
            margin: 10px 0 20px 0;
        }
        @media (max-width: 690px) {
            width: 100%;
            gap: 0;
        }
    }

    ${props => {
        if(props.copyright){
            return `
                background: #19324d;
                color: #fff;
                padding: 15px 0;
                margin-top: 30px;
                border-bottom: 5px solid #c7363c;

                div {
                    margin: 0 auto;
                }

                p {
                    font-size: .9em;
                    text-align: center;
                    width: 100%;

                    @media (max-width: 960px) {
                        font-size: .8em;
                    }
                }
            `
        }
    }}
`
export const FooterCell = styled.div`
    width: 50%;
    background: #ffffff;
    min-height: 100px;
    display: flex;
    padding: 20px 30px 20px 130px;
    box-shadow: 0 20px 20px rgba(0,0,0,0.03);
    align-items: center;
    position: relative;

    @media (max-width: 1310px) {
        min-height: 75px;
        padding: 0 20px 0 90px;
    }

    a{
        text-decoration: none;
        display: block;
        width: 100%;

        @media (max-width: 960px) {
            padding-left: 75px;
            font-size: .8em;
        }
    }
    
`
export const Img = styled.img`
    display: block;
    height: 100%;
    background: #c7363c;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
`
export const Heading4 = styled.h4`
    font-size: 1.75em;
    color: #19324d;
    text-transform: uppercase;

    @media (max-width: 1310px) {
        font-size: 1.5em;
    }
    @media (max-width: 320px){
        font-size: 1.2em;
    }
`
export const Text = styled.p`

`
