import React from 'react'
import { Content, Heading1, Heading2, ContentText, Text, List, Item, Pdf } from './PagesContent'
import { Link } from 'react-router-dom'
import pdfDoc from '../../images/predlog2020.pdf';

const AnalizaTrzista = () => {
    return (
        <Content>
            <Heading1>Анализа тржишта</Heading1>
            <ContentText>
                <Heading2>Анализа тржишта у сврху достизања циљева</Heading2>
                <Text>
                    Изградњом Аеродрома Росуље град Крушевац ће добити аеродром са референтним
                    кодом аеродрома „2Б“. Аеродром ће имати две ПСС и то једну травнату, дужине 800m и
                    ширине 30m, и полетно слетну стазу са вештачким коловозним застором дужине 1.300 m
                    дужине и ширине 30m, за које је неопходно израдити пројектно техничку документацију.
                </Text>
                <Text>
                    Планира се коришћење аеродрома за инструментално летење са могућношћу обављања
                    карго летова чиме ће град Крушевац добити још једну позитивну компаративну предност за
                    привлачење будућих инвестиција и олакшање транстпорта локалној привреди, као и
                    унапређењу туризма.
                </Text>
                <Text>
                    Просторним планом Републике Србије од 2010 – 2020 године (Службени гласник РС
                    88/10) локација у Крушевцу је предвиђена као летилиште која се може укључити у мрежу
                    регионалне понуде. Такође је добијена сагласност Министарства грађевинарства, саобраћаја
                    и инфраструктуре на регулациони план.
                </Text>
                <Pdf>
                    <Text>
                        За детаљније информације, преузмите PDF документ.
                    </Text>
                    <Link to={pdfDoc} target="_blank">Преузмите документ</Link>
                </Pdf>
            </ContentText>
        </Content>

    )
}

export default AnalizaTrzista
