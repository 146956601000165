import React from 'react'
import { Content, Heading1, Heading2, ContentText, Text, List, Item, Pdf } from './PagesContent'
import { Link } from 'react-router-dom'
import pdfDoc from '../../images/predlog2020.pdf';

const Organizacija = () => {
    return (
        <Content>
            <Heading1>Структура управљања</Heading1>
            <ContentText>
                <Heading2>Име директора и чланова надзорног одбора</Heading2>
                <Text>
                    <span className="grayed">Директор</span>
                    <List>
                        <Item>
                            <b>Директор предузећа</b> Драгана Павловић, <i>дипломирани правник</i>, именована је Решењем
                            Скупштине Града Крушевца о именовању директора Јавног предузећа „Аеродром Росуље”
                            са по Крушевац („Службени лист града Крушевца”, број 4/19) на ту функцију.
                        </Item>
                    </List>
                </Text>
                <Text>
                    <span className="grayed">Чланови Надзорног одбора</span>
                    <List>
                        <Item>
                            <span>Председница НО</span>Јованка Атанацковић - <i>дипломирани правник</i>
                        </Item>
                        <Item>
                            <span>Члан НО</span>Ђорђе Милић - <i>mагистар гео наука</i>
                        </Item>
                        <Item>
                            <span>Члан НО</span>Дијана Зуберовић - <i>дипломирани правник</i>
                        </Item>
                        <Item>
                            <span>Независан члан НО</span>Срђан Чокорило - <i>дипломирани инжењер саобраћаја</i>
                        </Item>
                        <Item>
                            <span>Представник запослених</span>Дејан Аксић - <i>дипломирани правник</i>
                        </Item>
                    </List>
                </Text>
                <Pdf>
                    <Text>
                        За детаљније информације, преузмите PDF документ.
                    </Text>
                    <Link to={pdfDoc} target="_blank">Преузмите документ</Link>
                </Pdf>
            </ContentText>
        </Content>

    )
}

export default Organizacija
