import React from 'react'
import { Content, Heading1, Heading2, ContentText, Text, List, Item, Pdf } from './PagesContent'
import { Link } from 'react-router-dom'
import pdfDoc from '../../images/predlog2020.pdf';

const Opreduzecu = () => {
    return (
        <Content>
            <Heading1>Подаци o предузећу</Heading1>
            <ContentText>
                <Heading2>Оснивање</Heading2>
                <Text>
                    <span>Јавно предузеће „Аеродром Росуље“ Крушевац</span>
                    основано је oдлуком СО Крушевац о оснивању
                    јавног предузећа „Аеродром Росуље“ 28.02.1995.
                    
                    Седиште Јавног предузећа је у Крушевцу,
                    Трг Косовских јунака број 6.
                </Text>
            <Heading2>Статус, правна форма и делатност предузећа</Heading2>
            <Text>
                <span>Пуно пословно име:</span>
                ЈАВНО ПРЕДУЗЕЋЕ „АЕРОДРОМ РОСУЉЕ“ СА ПО КРУШЕВАЦ
                (у дањем тексту Јавно предузеће)
                </Text>
            <Text>
                <span>Скраћено пословно име:</span>
                АЕРОДРОМ РОСУЉЕ СА ПО
                </Text>
            <Text>
                <span>Адреса:</span>
                ТРГ КОСОВСКИХ ЈУНАКА БРОЈ 6, КРУШЕВАЦ
                </Text>
            <Text>
                <span>Контакт телефон:</span>
                +381 37444713
                </Text>
            <Text>
                <span>E–mail:</span>
                аerodromrosulje@mts.rs
                </Text>
            <Text>
                <span>Оснивач:</span>
                Република Србија
                </Text>
            <Text>
                <span>Претежна делатност:</span>
                5223 - услужне делатности у ваздушном саобраћају
                Jaвно предузеће обавља делатност управљања аеродромом, као делатност од општег
                интереса, у складу са законом којим се уређује управљање аеродромима.
                </Text>
            <Text>
                <span>Матични број:</span>
                17028138
                </Text>
            <Text>
                <span>ПИБ:</span>
                104055713
                </Text>
            <Text>
                <span>ЈББК:</span>
                82490
                </Text>
            <Text>
                <span>Надлежнo министарствo:</span>
                Министарство грађевинарства, саобраћаја и инфраструктуре
                Републике Србије
                </Text>
                <Pdf>
                    <Text>
                        За детаљније информације, преузмите PDF документ.
                    </Text>
                    <Link to={pdfDoc} target="_blank">Преузмите документ</Link>
                </Pdf>
            </ContentText>
        </Content >

    )
}

export default Opreduzecu
