import React from 'react'
import { Content, Heading1, Heading2, ContentText, Text, List, Item } from './PagesContent'

const Kontakt = () => {
    return (
        <Content>
            <Heading1>Контакт подаци</Heading1>
            <ContentText>
                <Heading2>Контактирајте нас</Heading2>
                <Text>
                    <span>Седиште (адреса):</span>
                    Trg Kosovskih junaka broj 6, 37000 Kruševac
                </Text>
                <Text>
                    <span>Телефон за информације:</span>
                    037/444-713
                </Text>
                <Text>
                    <span>Мејл:</span>
                    aerodromrosulje@mts.rs
                </Text>
            </ContentText>
        </Content>

    )
}

export default Kontakt
